<template>
    <div>
        <statistics-bar-chart-template scale="daily" type="activeDevices">
            <template #title>{{ $t('activeDevices.dad') }}</template>
        </statistics-bar-chart-template>

        <statistics-bar-chart-template scale="weekly" type="activeDevices">
            <template #title>{{ $t('activeDevices.wad') }}</template>
        </statistics-bar-chart-template>

        <statistics-bar-chart-template scale="monthly" type="activeDevices">
            <template #title>{{ $t('activeDevices.mad') }}</template>
        </statistics-bar-chart-template>

        <statistics-bar-chart-template scale="yearly" type="activeDevices">
            <template #title>{{ $t('activeDevices.yad') }}</template>
        </statistics-bar-chart-template>
    </div>
</template>

<script>
import StatisticsBarChartTemplate from '../statistics-components/statistics-dynamic/StatisticsBarChartTemplate.vue';

export default {
    components: { StatisticsBarChartTemplate },
};
</script>

<style lang="scss" scoped></style>
